import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 16px;
  height: 16px;
`;

export const IconCross = () => (
  <Svg
    viewBox={'0 0 16 16'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M15.3761 12.2728C16.208 13.0981 16.208 14.4332 15.3761 15.2561C14.9602 15.6688 14.422 15.8897 13.8593 15.8897C13.3211 15.8897 12.7829 15.6955 12.367 15.2561L8.01223 10.9619L3.65749 15.2561C3.24159 15.6688 2.70336 15.8897 2.14067 15.8897C1.60245 15.8897 1.03976 15.6688 0.623853 15.2561C-0.207951 14.4332 -0.207951 13.0981 0.623853 12.2485L4.97859 7.95183L0.623853 3.62848C-0.207951 2.80557 -0.207951 1.44617 0.623853 0.620829C1.45566 -0.206943 2.80122 -0.206943 3.65749 0.620829L8.01223 4.93932L12.3425 0.620829C13.1988 -0.206943 14.5443 -0.206943 15.3761 0.620829C16.208 1.44617 16.208 2.80557 15.3761 3.62848L11.0214 7.95183L15.3761 12.2728Z'}
      fill={'#CCCCCC'}
    />
  </Svg>
);
