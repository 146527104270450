import React, { Dispatch, SetStateAction } from 'react';
import {
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import * as yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import styled from 'styled-components';
import { createUseStyles } from 'react-jss';
import { routes } from '~/common';
import {
  IconCheckboxChecked,
  IconCheckboxNotChecked,
} from '~/ui';

export interface IOrderForm {
  setIsSuccessRequest: Dispatch<SetStateAction<string>>;
  setErrorText: Dispatch<SetStateAction<string>>;
}

const useStyles = createUseStyles({
  error: {
    bottom: -22,
    position: 'absolute',
    marginLeft: '0px !important',
  },
});

const validationSchema = yup.object({
  name: yup.string().required('Заполните поле'),
  phone: yup
    .string()
    .min(18, 'Введите корректный номер')
    .required('Заполните поле'),
});

const Wrapper = styled.div`
  min-width: 100%;
  border-radius: 14px;
  box-sizing: border-box;
  align-self: center;
  margin-top: 40px;
`;

const CheckBoxWrapper = styled('div')`
  margin-right: 14px;
  .MuiSvgIcon-root {
    font-size: 40rem;
    padding: 0;
  }

  .MuiCheckbox-root {
    padding: 0;
  }
`;

const SubmitButton = styled('button')`
  font-weight: 500;
  font-size: 16px;
  border-radius: 16px;
  line-height: 125%;
  color: #F5F7FC;
  width: 100%;
  height: 60px;
  background: #1B2040;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
`;

export const NotAvailableForm: React.FC<IOrderForm> = (props) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      personalAgreement: true,
    },
    validationSchema,
    onSubmit: (values) => {
      // createOrder
      //   .request('order-create', {
      //     order: {
      //       ...values,
      //       date: correctDate,
      //     },
      //   })
      //   .then(
      //     () => {
      //       setIsSuccessRequest('success');
      //     },
      //     ({ message }) => {
      //       setErrorText(message);
      //       setIsOrderSent('error');
      //     },
      //   );
    },
  });

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={5}>
            <TextField
              variant={'filled'}
              fullWidth
              id={'name'}
              name={'name'}
              label={'Имя'}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: 'inherit',
                  border: '1px solid #bcbfd1',
                  borderRadius: 16,

                },
              }}
              InputLabelProps={{
                style: { color: '#BCBFD1' },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.error,
                },
              }}
            />
            <MaterialUiPhoneNumber
              disableDropdown
              fullWidth
              defaultCountry={'ru'}
              helperText={formik.touched.phone && formik.errors.phone}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              onChange={formik.handleChange('phone')}
              name={'phone'}
              value={formik.values.phone}
              variant={'filled'}
              label={'Телефон'}
              id={'phone'}
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: 'inherit',
                  border: '1px solid #bcbfd1',
                  borderRadius: 16,
                },
              }}
              InputLabelProps={{
                style: { color: '#BCBFD1' },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.error,
                },
              }}
            />
            <FormControlLabel
              control={(
                <CheckBoxWrapper>
                  <Checkbox
                    defaultChecked
                    icon={<IconCheckboxNotChecked/>}
                    checkedIcon={<IconCheckboxChecked/>}
                  />
                </CheckBoxWrapper>
              )}
              label={(
                <Typography
                  fontSize={13}
                  style={{ display: 'inline-flex', flexWrap: 'wrap', fontWeight: '600' }}
                >
                  <span style={{ marginRight: 4 }}>
                    Согласен на обработку
                  </span>
                  <Link
                    to={routes.userAgreement}
                    style={{
                      textDecoration: 'none',
                      color: '#8280EB',
                    }}
                  >
                    персональных данных
                  </Link>
                </Typography>
              )}
            />
            <SubmitButton
              type={'submit'}
            >
              Отправить данные
            </SubmitButton>
          </Stack>
        </form>
      </Wrapper>
    </FormikProvider>
  );
};
