import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { InputAdornment, TextField } from '@mui/material';
import { getMonth, getYear } from 'date-fns';
import ru from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { createUseStyles } from 'react-jss';
import {
  CustomDatePickerLeftArrowIcon,
} from './custom-date-picker-left-arrow-icon';
import {
  CustomDatePickerRightArrowIcon,
} from './custom-date-picker-right-arrow-icon';
import { CustomDatePickerWrapper } from './custom-date-picker-wrapper';

const MONTH = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export interface ICustomDatePicker {
  fieldName: string;
  id: string;
}

const useStyles = createUseStyles({
  datePicker: {
    boxSizing: 'border-box',
    width: ' 100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-end',
  },

  datePicker__button: {
    backgroundColor: 'inherit',
    border: 'none',
    cursor: 'pointer',
  },

  datePicker__date: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

export const CustomDatePicker: React.FC<ICustomDatePicker> = (props) => {
  const styles = useStyles();

  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name: props.fieldName, id: props.id });
  const years: Array<number> = [];

  for (let i = 1990; i <= getYear(new Date()) + 1; i++) {
    years.push(i);
  }

  registerLocale('ru', ru);

  const setDateFieldValue = ({
                               name,
                               value,
                             }: { name: string; value: Date | null; }) => {
    const formatValue = dayjs(value).format('DD/MM/YYYY');
    setFieldValue(name, formatValue);
  };

  useEffect(() => {
    if (!field.value) {
      setFieldValue('date', dayjs(new Date()).add(1, 'day').format('DD/MM/YYYY'));
    }
  }, [setFieldValue]);

  const selectedTimeForPicker = useMemo(() => {
    if (field.value) {
      const fieldValue = field.value;
      return dayjs(`${fieldValue.slice(3, 5)}/${fieldValue.slice(0, 2)}${fieldValue.slice(5)}`).toDate();
    }
  }, [field]);

  return (
    <CustomDatePickerWrapper>
      <DatePicker
        dateFormat={'dd/mm/yyyy'}
        locale={'ru'}
        disabledKeyboardNavigation
        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...field}
        {...{ name: props.fieldName, id: props.id }}
        focusSelectedMonth={false}
        customInput={(
          <TextField
            fullWidth
            placeholder={'Дата установки'}
            label={'Дата установки'}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'start'}>
                  <CalendarTodayIcon htmlColor={'#BCBFD1'}/>
                </InputAdornment>
              ),
            }}
          />
        )}
        renderCustomHeader={
          ({
             date,
             changeYear,
             changeMonth,
             decreaseMonth,
             increaseMonth,
             prevMonthButtonDisabled,
             nextMonthButtonDisabled,
           }) => (
            <div
              className={styles.datePicker}
            >
              <button
                type={'button'}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                className={styles.datePicker__button}
              >
                <CustomDatePickerLeftArrowIcon/>
              </button>
              <div
                className={styles.datePicker__date}
              >
                <select
                  value={MONTH[getMonth(date)]}
                  onChange={({ target: { value } }) => changeMonth(MONTH.indexOf(value))}
                >
                  {MONTH.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(Number(value))}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <button
                type={'button'}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                className={styles.datePicker__button}
              >
                <CustomDatePickerRightArrowIcon/>
              </button>
            </div>
          )
        }
        selected={(field.value && selectedTimeForPicker) || null}
        onChange={(value) => {
          setDateFieldValue({ name: props.fieldName, value });
        }}
      />
    </CustomDatePickerWrapper>
  );
};
