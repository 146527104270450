import React, { useState } from 'react';
import styled from 'styled-components';
import { IconWorker } from '~/ui';
import { Layout } from './layout';
import { MainForm } from './main-form';
import { NotAvailableComponent } from './not-available-component';
import { ProcessingComponent } from './processing-component';

const Wrapper = styled.div`
  margin-bottom: 210px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 209px 0 0;
  box-sizing: border-box;
  position: relative;
  z-index: 50;
  column-gap: 102px;
  @media (max-width: 920px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    padding-top: 101px;
    column-gap: 0;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  @media (max-width: 920px) {
    margin-bottom: 75px;
    padding-top: 0;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 38px;
  line-height: 125%;
  margin-bottom: 125px;
  @media (max-width: 920px) {
    font-size: 30px;
    line-height: 140%;
    margin-bottom: 53px;
  }
`;

export const Main: React.FC = () => {
  const [contentState, setContentState] = useState('');
  const [errorText, setErrorText] = useState('');

  if (contentState === 'notAvailable') {
    return <NotAvailableComponent/>;
  }

  if (contentState === 'success' || contentState === 'error') {
    const isSuccess = contentState === 'success';

    return (
      <ProcessingComponent
        isSuccess={isSuccess}
        title={'Вы успешно активировали сертификат!'}
        errorText={errorText}
        subtitleText={'Наш менеджер скоро свяжется с вами для уточнения деталей.'}
      />
    );
  }

  return (
    <Layout isNeedHeader>
      <Wrapper>
        <Left>
          <Text>
            Заполните форму и активируйте сертификат на установку бытовой
            техники
          </Text>
          <IconWorker/>
        </Left>
        <Right>
          <MainForm
            setErrorText={setErrorText}
            setIsOrderSent={setContentState}
          />
        </Right>
      </Wrapper>
    </Layout>
  );
};
