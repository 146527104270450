import { Stack } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mediaBreakpoints } from '../common';
import caseIcon from '../images/footer-case.svg';
import mapDotIcon from '../images/footer-map-dot.svg';
import phoneIcon from '../images/footer-phone.svg';
import tgIcon from '../images/telegram.svg';
import fbg from '../images/footerbg.svg';
import logoWhite from '../images/logo-white.svg';
import { Container } from './container';
import { LinksToMobileApps } from './links-to-mobile-apps';

const Wrapper = styled.div`
  font-size: 1px;
`;

const FooterBox = styled.div`
  background: url(${fbg}) center center / cover no-repeat, linear-gradient(269.35deg, #808BFD 37.18%, #7958D5 72.05%);
  color: white;
  padding: 30em 0;

  span, .copyright {
    font-size: 18em;
  }

  .copyright {
    margin-right: 10px;
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .user-agreenment {
    font-size: 18em;
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .right-block {
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      align-items: center;
      * {
        display: flex;
        align-items: center;
        text-align: center;
      }
    }
  }

  .bot-bot {
    @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
      flex-direction: column;
      row-gap: 15em;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    flex-direction: column;
  }
`;

const FlexItem = styled.div`
  flex: 1;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 36em auto auto;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    grid-template-columns: 36em auto;
    row-gap: 20em;
    .last {
      grid-column-start: 2;
    }
  }

  & > img {
    width: 28em;
    flex-shrink: 0;
  }
`;
const PhonesWrapper = styled(Row)`
`;

const Divider = styled.div`
  background: rgba(255, 255, 255, 0.5);
  width: 2em;
  margin: 0 100em;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    width: auto;
    height: 2em;
    margin: 30em 0;
  }
`;
const Phone = styled.a`
  text-decoration: none;
  color: inherit;
`;
const PhoneCaption = styled.div`
  font-weight: 400;
  font-size: 12em;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.5);
`;

const ContainerLocal = styled(Container)`
  max-width: 1000em;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    max-width: calc(100% - 32em);
  }
`;

export const Footer: React.FC<{ ContainerFromProps?: React.FC; }> = ({ ContainerFromProps }) => {
  const ContainerSelected = ContainerFromProps ?? ContainerLocal;

  return (
    <Wrapper className={'footer'}>
      <FooterBox>
        <ContainerSelected>
          <Flex>
            <FlexItem>
              <Stack spacing={'48em'}>
                <Row>
                  <img src={caseIcon}/>
                  <div>
                    <span>ООО “Техника вашего дома”</span>
                    <br/>
                    <span>ИНН 5047257258</span>
                  </div>
                </Row>
                <Row>
                  <img src={mapDotIcon}/>
                  <span className={'address'}>141402, обл. Московская, г. Химки,<br/> ул. Ленинградская, влд. 39 стр. 5, помещ. ОВ01_12
                  </span>
                </Row>
                <PhonesWrapper>
                  <img src={tgIcon}/>
                  <Phone href={'https://t.me/MytechSupportBot'} className={'last'}>
                    <span>@MytechSupportBot</span>
                  </Phone>
                </PhonesWrapper>
              </Stack>
            </FlexItem>
            <Divider/>
            <FlexItem>
              <Stack
                spacing={'40em'}
                className={'right-block'}
              >
                <Stack spacing={'16em'}>
                  <a href={'https://mytech.global'}>
                    <img src={logoWhite} alt={'МайТех'} height={30}/>
                  </a>
                  <div>
                    <span>Скачивайте приложение МайТех в Google Play и App Store</span>
                  </div>
                </Stack>
                <div>
                  <LinksToMobileApps/>
                </div>
                <div className={'bot-bot'}>
                  <a
                    /* eslint-disable-next-line no-restricted-globals */
                    href={location?.href === 'https://mytech.global/' ? '/#screen1' : 'https://mytech.global/'}
                    className={'copyright'}
                  >
                    © 2022 mytech.global
                  </a>
                  <Link
                    to={'/about/oferta'}
                    className={'user-agreenment'}

                  >
                    Публичная оферта
                  </Link>
                </div>
              </Stack>
            </FlexItem>
          </Flex>
        </ContainerSelected>
      </FooterBox>
    </Wrapper>
  );
};
