import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import styled from 'styled-components';
import { IconLogo } from '~/ui';
import { Layout } from './layout';
import { NotAvailableForm } from './not-available-form';
import { ProcessingComponent } from './processing-component';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 622px;
  box-sizing: border-box;
  padding: 50px 60px;
  background: white;
  margin: 220px auto 120px;
  border-radius: 14px;
  @media (max-width: 450px) {
    background: inherit;
    padding: 0;
  }
`;

const useStyles = createUseStyles({
  stackWrapper: {
    alignItems: 'center',
  },
  subTitle: {
    color: 'rgba(27, 32, 64, 0.38)',
    maxWidth: 425,
    textAlign: 'center',
  },
});

export const NotAvailableComponent: React.FC = () => {
  const [isSuccessRequest, setIsSuccessRequest] = useState('');
  const [errorText, setErrorText] = useState();
  const styles = useStyles();

  if (isSuccessRequest === 'success' || isSuccessRequest === 'error') {
    const isSuccess = isSuccessRequest === 'success';

    return (
      <ProcessingComponent
        isSuccess={isSuccess}
        title={'Данные отправлены'}
        errorText={errorText}
        subtitleText={'Мы сообщим вам, когдя появимся в вашем регионе'}
      />
    );
  }

  return (
    <Layout isNeedHeader>
      <Wrapper>
        <Stack
          spacing={5}
          className={styles.stackWrapper}
        >
          <IconLogo/>
          <Typography
            fontWeight={'500'}
            sx={{
              fontSize: ['24px', '28px', '32px', '32px', '32px'],
            }}
            textAlign={'center'}
          >
            Нашего сервиса здесь пока нет
          </Typography>
          <Typography
            sx={{
              fontSize: ['18px', '20px', '24px', '24px', '24px'],
            }}
            textAlign={'center'}
            className={styles.subTitle}
          >
            Сегодня мы не сможем помочь вам.
            Оставьте свои контакты, будем рады сообщить, когда появимся
          </Typography>
        </Stack>
        <NotAvailableForm
          setIsSuccessRequest={setIsSuccessRequest}
          setErrorText={setErrorText}
        />
      </Wrapper>
    </Layout>
  );
};
