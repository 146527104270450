export const routes = {
  main: '/',
  oferta: '/about/oferta',
  userAgreement: '/about/personal',
};

export const workTimeRange: Array<string> = [
  '10:00–12:00',
  '11:00–13:00',
  '12:00–14:00',
  '13:00–15:00',
  '14:00–16:00',
  '15:00–17:00',
  '16:00–18:00',
  '17:00–19:00',
  '18:00–20:00',
  '19:00–21:00',
  '20:00–22:00',
  '21:00–23:00',
];
