import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { LocalStorageRepository } from '~/1st-core';
import { registerDependency } from '~/1st-di';
import { NotifyService } from '~/1st-react-notify';
import { App, AppSessionService } from '~/app';
import { routes } from '~/common';
import { Main, MainService } from '~/main';
import { ScrollToTop } from '~/main/components/scroll-to-top';
import { Oferta } from '~/oferta';
import { RoleService } from '~/role';
import { SessionPanelService } from '~/session-panel';
import { Container, Footer, Header, mediaBreakpoints } from '~/ui';
import { UserService } from '~/user';
import { UserAgreement } from '~/user-agreement';

registerDependency('STORAGE_REPOSITORY', () => new LocalStorageRepository());
registerDependency('NOTIFY_SERVICE', () => new NotifyService());
registerDependency('SESSION_SERVICE', () => new AppSessionService());

registerDependency('ROLE_SERVICE', () => new RoleService());
registerDependency('SESSION_PANEL_SERVICE', () => new SessionPanelService());
registerDependency('USER_SERVICE', () => new UserService());
registerDependency('MAIN_SERVICE', () => new MainService());

const container = document.getElementById('root');
const root = createRoot(container);

const ContainerLocal = styled(Container)`
  max-width: 1000rem;
  margin-left: 50px;
  padding: 100px 0;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    max-width: calc(100% - 32px);
    margin-left: 10px;
    padding: 80px 0;
  }
`;

root.render((
  <App>
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path={routes.main} element={<Main/>}/>
        <Route
          path={'/about/oferta'}
          element={(
            <>
              <ContainerLocal>
                <Header showGoToHomeButton alignToLeft/>
                <Oferta/>
              </ContainerLocal>
              <Footer/>
            </>
          )}
        />
        <Route
          path={'/about/personal'}
          element={(
            <>
              <ContainerLocal>
                <Header showGoToHomeButton alignToLeft/>
                <UserAgreement/>
              </ContainerLocal>
              <Footer/>
            </>
          )}
        />
        <Route path={'*'} element={<>404</>}/>
      </Routes>
    </BrowserRouter>
  </App>
));
