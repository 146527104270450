import React from 'react';
import styled from 'styled-components';
import { Processing, ProcessingProps } from '~/ui';
import { Layout } from './layout';
import { IconLogo } from '../../ui/icons/icon-logo';

interface ProcessingComponentProps extends ProcessingProps {
  errorText?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 546px;
  width: 100%;
  margin: 84px auto 120px;
`;

const ProcessingWrapper = styled.div`
    margin: 120px auto 0 ;
    background: white;
    padding: 50px 60px;
    border-radius: 14px;
  @media (max-width: 450px) {
    padding:  30px 0;
    background: inherit;
    margin-top: 40px;
  }
`;

export const ProcessingComponent: React.FC<ProcessingComponentProps> = (props) => (
  <Layout isNeedHeader={false}>
    <Wrapper>
      <IconLogo/>
      <ProcessingWrapper>
        <Processing
          isSuccess={props.isSuccess}
          errorText={props.errorText}
          title={props.title}
          subtitleText={props.subtitleText}
        />
      </ProcessingWrapper>
    </Wrapper>
  </Layout>
);
