import React from 'react';
import styled from 'styled-components';
import { Layout } from '~/main/components/layout';
import { Oferta as OfertaComponent } from '~/ui/components/oferta';

const Wrapper = styled.div`
  margin-bottom: 150px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Oferta = () => {
  console.log('asdasd');

  return (
    <Layout isNeedHeader showBackButton>
      <Wrapper>
        <OfertaComponent/>
      </Wrapper>
    </Layout>
  );
};
