import React from 'react';
import styled from 'styled-components';
import { Layout } from '~/main/components/layout';
import { UserAgreement as UserAgreementComponent } from '~/ui/components/user-agreement';

const Wrapper = styled.div`
  margin-bottom: 150px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const UserAgreement = () => (
  <Layout isNeedHeader showBackButton>
    <Wrapper>
      <UserAgreementComponent/>
    </Wrapper>
  </Layout>
);
