import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { routes } from '~/common';
import {
  CustomDatePicker,
  IconCheckboxChecked,
  IconCheckboxNotChecked,
  IconInfoForm,
  mediaBreakpoints,
} from '~/ui';
import { useMainService } from '../di';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  //.MuiFilledInput-input {
  //  padding: 25px 12px 14px;
  //  background: none !important;
  //}
  //
  //.MuiFilledInput-root {
  //  background-color: white !important;
  //  border: 1px solid #bcbfd1;
  //  border-radius: 16px;
  //  padding: 0 12px;
  //  box-sizing: border-box;
  //}
  //
  //.MuiInputLabel-root {
  //  left: 13px;
  //  top: 4px;
  //}
  //
  //.MuiInputAdornment-root {
  //  margin-right: 14px;
  //}
  //
  //.MuiFormControlLabel-root {
  //  margin-left: 0;
  //}
`;

const FormFooter = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 31px;
`;

const Wrapper = styled.div`
  padding: 40px;
  background: #ffffff;
  width: 440px;
  border-radius: 14px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    width: calc(100% + 36px);
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
  }
`;

const CheckBoxWrapper = styled('div')`
  margin-right: 14px;

  .MuiSvgIcon-root {
    font-size: 40px;
    padding: 0;
  }

  .MuiCheckbox-root {
    padding: 0;
  }
`;

const SubmitButton = styled('button')`
  font-weight: 500;
  font-size: 18px;
  border-radius: 16px;
  line-height: 125%;
  color: #F5F7FC;
  width: 100%;
  height: 60px;
  background: #1B2040;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  outline: none;
  @media screen and (max-width: ${mediaBreakpoints.desktopToMobile}px) {
    height: 50px;
  }

  &:hover {
    background: #090E2B;
  }

  &:active {
    background: #060A21;
  }

  &:focus {
    border: 1px solid #EF5DA8;
  }
`;

const TextFieldWrapper = styled('div')`
  width: 100%;
  position: relative;
  z-index: 1;

  & > * {
    width: 100%;

    .MuiInputAdornment-root {
      margin-right: 12px;
    }

    .MuiInputBase-root:not(.MuiInputBase-multiline) {
      border-radius: 20px;
      height: 64px;

      input {
        font-size: 16px;
        padding: 16.5px 14px 16.5px 24px;
        outline: none;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #BCBFD1;
      border-width: 1px !important;
      font-size: 16px !important;

      legend {
        font-size: 16px !important;
      }
    }

    .Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
      border: 1px solid #838AAF !important;
    }

    .MuiInputBase-multiline {
      padding: 16.5px 14px 16.5px 24px;
      border-radius: 20px;

      textarea {
        font-size: 16px;
        line-height: 1;
        height: auto;

      }
    }

    .MuiInputLabel-root {
      font-size: 16px;
      left: 7px;
      top: 3px;
      color: #BCBFD1;

      &.Mui-focused:not(.Mui-error) {
        color: #838AAF;
      }
    }

    .MuiFormHelperText-root {
      font-size: 14px;
    }
  }
`;

export interface MainFormProps {
  setIsOrderSent: Dispatch<SetStateAction<string>>;
  setErrorText: Dispatch<SetStateAction<string>>;
}

export const MainForm: React.FC<MainFormProps> = (props) => {
  const mainService = useMainService();

  const formik = useFormik({
    initialValues: {
      code: '',
      name: '',
      phone: '',
      address: '',
      date: '',
      comment: '',
      personalAgreement: true,
    },
    validationSchema: yup.object({
      code: yup.string().required('Заполните поле'),
      name: yup.string().required('Заполните поле'),
      phone: yup
        .string()
        .min(18, 'Введите корректный номер')
        .required('Заполните поле'),
      address: yup.string().required('Заполните поле'),
      date: yup.string().required('Заполните поле'),
    }),
    onSubmit: (values) => {
      let correctDate = dayjs(
        `${values.date.slice(3, 5)}/${values.date.slice(
          0,
          2,
        )}${values.date.slice(5)}`,
      );
      correctDate = correctDate.hour(Number('12:00–14:00'.slice(0, 2)));

      mainService.send({
        ...values,
        date: correctDate.toDate(),
      })
        .then(
          () => {
            props.setIsOrderSent('success');
          },
          (error) => {
            console.log('mainService.send error >>>', error);
            props.setErrorText(error.message);
            props.setIsOrderSent('error');
          },
        );
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Form onSubmit={handleSubmit}>
          <TextFieldWrapper>
            <TextField
              fullWidth
              id={'code'}
              name={'code'}
              label={'Код активации'}
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextField
              fullWidth
              id={'name'}
              name={'name'}
              label={'Имя'}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <MaterialUiPhoneNumber
              disableDropdown
              fullWidth
              defaultCountry={'ru'}
              helperText={formik.touched.phone && formik.errors.phone}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              onChange={formik.handleChange('phone')}
              name={'phone'}
              value={formik.values.phone}
              label={'Телефон'}
              placeholder={'Телефон'}
              id={'phone'}
              variant={'outlined'}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextField
              fullWidth
              id={'address'}
              name={'address'}
              label={'Город, улица, дом, квартира'}
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <Tooltip
                      title={'услуги оказываются в Москве и МО'}
                      placement={'top'}
                      arrow
                    >
                      <div>
                        <IconInfoForm/>
                      </div>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </TextFieldWrapper>
          <TextFieldWrapper style={{ zIndex: 2 }}>
            <CustomDatePicker
              fieldName={'date'}
              id={'date-input'}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextField
              fullWidth
              multiline
              rows={3}
              id={'comment'}
              name={'comment'}
              label={'Комментарии (необязательно)'}
              value={formik.values.comment}
              onChange={formik.handleChange}
            />
          </TextFieldWrapper>
          <FormFooter>
            <FormControlLabel
              control={(
                <CheckBoxWrapper>
                  <Checkbox
                    defaultChecked
                    icon={<IconCheckboxNotChecked/>}
                    checkedIcon={<IconCheckboxChecked/>}
                  />
                </CheckBoxWrapper>
              )}
              label={(
                <Typography
                  fontSize={13}
                  style={{
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    fontWeight: '600',
                  }}
                >
                  <span style={{ marginRight: 4 }}>
                    Согласен на обработку
                  </span>
                  <Link
                    to={routes.userAgreement}
                    style={{
                      textDecoration: 'none',
                      color: '#8280EB',
                    }}
                  >
                    персональных данных
                  </Link>
                </Typography>
              )}
            />
            <SubmitButton type={'submit'}>
              Оформить заявку
            </SubmitButton>
          </FormFooter>
        </Form>
      </Wrapper>
    </FormikProvider>
  );
};
