import { NotifyService, useNotifyService } from '~/1st-react-notify';
import { SentOrder } from '~/api';
import { useApi } from '~/app';

export class MainService {
  notifyService: NotifyService;

  constructor() {
    this.notifyService = useNotifyService();
  }

  async send(data: SentOrder): Promise<void> {
    const api = useApi();
    await api.order.send({ data });
  }
}
