import { mapOrVoid } from '~/1st-api';

export type SentOrder = {
  code?: string;
  name?: string;
  phone?: string;
  address?: string;
  comment?: string;
  date?: Date;
};

export type SentOrderRaw = {
  code?: string;
  name?: string;
  phone?: string;
  address?: string;
  comment?: string;
  date?: string;
};

export function mapSentOrder(data: SentOrder): SentOrderRaw {
  return {
    code: mapOrVoid(data.code, String),
    name: mapOrVoid(data.name, String),
    phone: mapOrVoid(data.phone, String),
    address: mapOrVoid(data.address, String),
    comment: mapOrVoid(data.comment, String),
    date: data.date?.toISOString(),
  };
}

export function mapSentOrderRaw(dataRaw: SentOrderRaw): SentOrder {
  return {
    code: mapOrVoid(dataRaw.code, String),
    name: mapOrVoid(dataRaw.name, String),
    phone: mapOrVoid(dataRaw.phone, String),
    address: mapOrVoid(dataRaw.address, String),
    comment: mapOrVoid(dataRaw.comment, String),
    date: mapOrVoid(dataRaw.date, (date) => new Date(date)),
  };
}
